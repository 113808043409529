import React, {useState} from 'react';
import {Select, Spin, Typography} from "antd";
import CustomTable from "../../../shared/CustomTable/CustomTable";
import {roleItems} from "./mocks";
import {checkRole} from "../../../../helpers";

import CheckBoxesForTable from "../CheckBoxesForTable/CheckBoxesForTable";
import {ReactComponent as DownIcon} from "../../../../svg/icon/down-arrow.svg";
import styles from './UserManagementTable.module.css';

const {Text} = Typography;
const UserManagementTable = ({
                                 onUsersSelect,
                                 initialTableData,
                                 projects,
                                 updateUsersRoleAndAccess,
                                 selectedUsers,
                                 isAllowed
                             }) => {
    const [isRoleOpen, setIsRoleOpen] = useState(null);
    const [isProjectsOpen, setIsProjectsOpen] = useState(null);
    const [changingPermitLoading, setChangingPermitLoading] = useState(null);
    const [roleChangedLoading, setRoleChangedLoading] = useState(null);


    const onProjectClick = async (newOpen, user, list, onEmptyList) => {
        if (newOpen) {
            setIsProjectsOpen(user.key);
            setIsRoleOpen(null);
        } else {
            setChangingPermitLoading(user.id);
            onEmptyList([]);
            setIsProjectsOpen(null);
            const foundUser = initialTableData.find(data => data.id === user.id);
            if (foundUser.projects.length === 0 && list.length === 0) {
                setChangingPermitLoading(false)
                return;
            }
            await updateUsersRoleAndAccess(user.id, {projects: list}).finally(() => setChangingPermitLoading(null));
        }
    };

    const onRoleClick = (key) => {
        if (key === isRoleOpen) {
            return setIsRoleOpen(null);
        }
        setIsRoleOpen(key);
        setIsProjectsOpen(null);
    };

    const handleRoleChange = async (value, rowData) => {
        setRoleChangedLoading(rowData.id);
        await updateUsersRoleAndAccess(rowData.id, {title: checkRole(value)}).finally(() => setRoleChangedLoading(null));
        setIsRoleOpen(null);
    };


    const rowSelection = {
        selectedRowKeys: selectedUsers,
        onChange: (selectedRowKeys) => {
            onUsersSelect(selectedRowKeys);
        },
    };

    const columns = [
        {
            title: 'Username',
            dataIndex: 'username',
            width: '40%',
            render: (record) => (
                <Text>{record}</Text>
            )
        },
        {
            title: 'Role',
            dataIndex: 'role',
            width: '20%',
            render: (record, rowData) => {
                return (
                  roleChangedLoading === rowData.id ?
                    <div className='loading-container'>
                        <Spin size='small'/>
                    </div> :
                    <Select
                      disabled={isAllowed}
                      open={isRoleOpen === rowData.key}
                      onClick={() => onRoleClick(rowData.key)}
                      className={styles.select}
                      value={record}
                      onSelect={(value) => handleRoleChange(value, rowData)}
                      options={roleItems}
                      suffixIcon={
                          <DownIcon
                            className={`
                                ${styles.selectIcon} 
                                ${isRoleOpen === rowData.key && !isAllowed && styles.activeSelectIcon}
                            `}
                          />
                      }
                    />
                )
            }
        },
        {
            title: 'Access',
            dataIndex: 'projects',
            width: '40%',
            className: styles.accessColumn,
            render: (record, rowData) => {
                return (
                  <>
                      {rowData.role === 'admin' ? (
                        'All projects'
                      ) : (
                        <CheckBoxesForTable
                          projects={projects}
                          record={record}
                          rowData={rowData}
                          onProjectClick={onProjectClick}
                          isProjectsOpen={isProjectsOpen}
                          isAllowed={isAllowed}
                          loading={changingPermitLoading}
                        />
                      )}
                  </>
                );
            }
        },
    ];

    return (
        <div>
            <CustomTable
                columns={columns}
                data={initialTableData}
                rowSelection={!isAllowed && {
                    type: 'checkbox',
                    ...rowSelection,
                }}
            />
        </div>
    );
};

export default UserManagementTable;
