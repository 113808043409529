import {useLocation} from "react-router-dom";
import React from "react";
import {message} from 'antd';
import {GENERATIONS_LIMIT_MESSAGE} from './constants';

export const getUserLocalStorage = (name, key) => {
    const tokens = localStorage.getItem(name || 'tokens');
    const parsedTokens = tokens ? JSON.parse(tokens) : {};

    if (key) {
        return parsedTokens[key] || null;
    }

    return parsedTokens;
};

export const addLocalStorage = (name, data) => {
    const stringData = JSON.stringify(data);
    window.localStorage.setItem(name || 'tokens', stringData);
}
export const logoutLocalStorage = () => {
   localStorage.clear();
};


export const saveUserInfo = (data) => {
    const userTokens = {
        csrf: data.csrf,
        refresh: data.refresh,
    };
    const userInfo = {
        roles: data.roles_and_perms,
        isSuperUser: data.is_superuser,
    };
    addLocalStorage('tokens', userTokens);
    addLocalStorage('userInfo', userInfo);
    addLocalStorage('ru_region', data.ru_region);
};

export const formatNumbers = (number) => {
    if (!number && number !== 0) return;
    const numString = number.toString();
    return numString.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const dateOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
}

export const checkRole = (roleIdOrName) => {
    const roleTypes = {
        1: 'admin',
        2: 'editor',
        3: 'viewer',
    };

    if (typeof roleIdOrName === 'string') {
        for (const id in roleTypes) {
            if (roleTypes[id] === roleIdOrName) {
                return parseInt(id);
            }
        }
        return null;
    }
    return roleTypes[roleIdOrName];
};

export const shortenString = (inputString, length) => {
    if (inputString.length > length) {
        return inputString.substring(0, length - 3) + '...';
    }
    return inputString;
};

export const filterOrganizationsByRole = (organizations, rolesArray) => {
    return organizations.filter(org => {
        const role = rolesArray.find(role => role.organization === org.id);
        return role && role.role === 1;
    });
};

export const useQuery = () => {
    const {search} = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const convertNoticeCode = (value) => {
    return value === null ? value : value.toString();
};

export const removeDuplicates = (list) => {
    const uniqueIds = new Set();
    return list.filter(item => !uniqueIds.has(item?.id) && uniqueIds.add(item?.id));
};

export const handleGenerationsLimitError = (error) => {
	const errorMessage = error.response?.data[0];
	if (errorMessage && errorMessage.startsWith(GENERATIONS_LIMIT_MESSAGE)) {
		message.error(errorMessage);
	} else {
		message.error(error.message);
	}
};

export const convertToArrayOfStrings = (string) => {
	return	string.split(',').map(function(item) {
		return item.trim();
	});
};

export const handleOneLineError = (error, setError) => {
	const {data} = error.response;
	let errorField = '';
	Object.keys(data).forEach(key => {
		errorField = data[key][0];
	});
	setError(errorField);
};

export const cleanFontName = (name) => name.replace(/\.(ttf|otf|woff|woff2|eot|svg)$/i, '')
