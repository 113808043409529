import React, { useEffect, useState } from 'react';
import Konva from 'konva';

import GraphicSidebarText from '../../components/properties/GraphicPropertiesText/GraphicPropertiesText';
import GraphicSidebarImage from '../../components/properties/GraphicPropertiesImage/GraphicPropertiesImage';
import GraphicSidebarGroup from '../../components/properties/GraphicPropertiesGroup/GraphicPropertiesGroup';
import GraphicSidebarRect from '../../components/properties/GraphicPropertiesRect/GraphicPropertiesRect';
import GraphicSidebarStar from '../../components/properties/GraphicPropertiesStar/GraphicPropertiesStar';
import GraphicSidebarEllipse from '../../components/properties/GraphicPropertiesEllipse/GraphicPropertiesEllipse';
import GraphicSidebarPolygon from '../../components/properties/GraphicPropertiesPolygon/GraphicPropertiesPolygon';
import GraphicSidebarLine from '../../components/properties/GraphicPropertiesLine/GraphicPropertiesLine';
import Header from '../../components/properties/PropertiesHeader/PropertiesHeader';
import Tab from '../../components/properties/PropertyTab/PropertyTab';

import styles from './GraphicProperties.module.css';

export default function (props) {
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    setActiveTab(1);
  }, [props.selectedGroup]);

  const tabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const defaultProps = {
    activeTab,
    product: props.product,
    productFields: props.productFields,
    group: props.selectedGroup,
    onClose: props.onCloseProperties,
    refreshToggle: props.refreshToggle,
    checkCondition: props.checkCondition,
    attachToElement: props.attachToElement,
    detachElement: props.detachElement,
    onPropertyChanged: props.onPropertyChanged,
    reloadingProducts: props.reloadingProducts,
  };

  const getPropertiesContent = () => {
    switch (props.selectedGroup.getAttr('elementType')) {
      case 'text':
        return (
          <GraphicSidebarText
            defaultProps={defaultProps}
            scaleText={props.scaleText}
            fonts={props.fonts}
            uploadFont={props.uploadFont}
          />
        );
      case 'image':
        return (
          <GraphicSidebarImage
            defaultProps={defaultProps}
            scaleImage={props.scaleImage}
            uploadFile={props.uploadFile}
            userImages={props.userImages}
            deleteImage={props.deleteImage}
            stage={props.stage}
          />
        );
      case 'group':
        if (props.selectedGroup instanceof Konva.Transformer) return null;
        return (
          <GraphicSidebarGroup
            defaultProps={defaultProps}
            distributeGroup={props.distributeGroup}
            destroyDistributedGroup={props.destroyDistributedGroup}
            scaleText={props.scaleText}
          />
        );
      case 'rect':
        return <GraphicSidebarRect defaultProps={defaultProps} />;
      case 'star':
        return <GraphicSidebarStar defaultProps={defaultProps} />;
      case 'ellipse':
        return <GraphicSidebarEllipse defaultProps={defaultProps} />;
      case 'polygon':
        return <GraphicSidebarPolygon defaultProps={defaultProps} />;
      case 'line':
        return <GraphicSidebarLine defaultProps={defaultProps} />;
      default:
        return null;
    }
  };

  return (
    <aside className={styles.sidebar}>
      <div className={styles.headerBlock}>
        <Header onClose={props.onCloseProperties} caption='Properties' />
        <Tab activeTab={activeTab} setActiveTab={tabClick} />
      </div>
      {getPropertiesContent()}
    </aside>
  );
}
