import { Button, InputNumber, Typography } from 'antd';
import React, { useEffect, useState } from 'react';

import {
  emptyColor,
  isColorSet,
  isSeparatelyEnabled,
} from '../../properties/PropertiesHelper';
import ColorEditor from '../ColorEditor/ColorEditor';
import { ReactComponent as CornerRadiusIcon } from 'svg/graphicEditor/corner-radius-small.svg';
import { ReactComponent as CornerBottomLeft } from 'svg/graphicEditor/left-bottom-corner-small.svg';
import { ReactComponent as CornerTopLeft } from 'svg/graphicEditor/left-top-corner-small.svg';
import { ReactComponent as MinusIcon } from 'svg/graphicEditor/minus-big-black.svg';
import { ReactComponent as PlusIcon } from 'svg/graphicEditor/plus-big-black.svg';
import { ReactComponent as CornerBottomRight } from 'svg/graphicEditor/right-bottom-corner-small.svg';
import { ReactComponent as CornerTopRight } from 'svg/graphicEditor/right-top-corner-small.svg';
import { ReactComponent as BorderSizeIcon } from 'svg/graphicEditor/border-width-small.svg';

import globalStyles from '../../GlobalGraphicEditor.module.css';
import styles from './BackgroundBorderControl.module.css';

const { Text } = Typography;

export default function (props) {
  const [isBgEnabled, setBgEnabled] = useState();

  const [bgColor, setBgColor] = useState();

  const [cornerTL, setCornerTL] = useState();
  const [cornerTR, setCornerTR] = useState();
  const [cornerBR, setCornerBR] = useState();
  const [cornerBL, setCornerBL] = useState();

  const [isBgBorderSeparatelyEnabled, setBgBorderSeparatelyEnabled] =
    useState(false);
  const [cornerWidth, setCornerWidth] = useState(0);

  const { element } = props;

  const hasBorder = props.border === undefined || props.border === true;

  useEffect(() => {
    const color = element.getAttr('extraBg');
    setBgColor(color !== emptyColor ? color : undefined);

    const cornerLt = (element.getAttr('cornerRadius') || [0, 0, 0, 0])[0];
    const cornerTr = (element.getAttr('cornerRadius') || [0, 0, 0, 0])[1];
    const cornerBr = (element.getAttr('cornerRadius') || [0, 0, 0, 0])[2];
    const cornerBl = (element.getAttr('cornerRadius') || [0, 0, 0, 0])[3];
    setCornerTL(cornerLt);
    setCornerTR(cornerTr);
    setCornerBR(cornerBr);
    setCornerBL(cornerBl);

    const cornerWidth = element.getAttr('strokeWidth') || 0;
    const cornerColor = element.getAttr('stroke');
    setCornerWidth(!cornerColor ? 0 : cornerWidth);

    const isEnabled = props.isEnabledFunc ? props.isEnabledFunc() : isColorSet(element.getAttr('extraBg'));
    setBgEnabled(isEnabled);
    setBgBorderSeparatelyEnabled(
      isSeparatelyEnabled(cornerLt, cornerTr, cornerBr, cornerBl),
    );

    if (props.onEnabledChanged) {
      props.onEnabledChanged(isEnabled);
    }
  }, [element]);

  const changeBgColor = (color) => {
    if (color) {
      element.setAttr('fill', color);
      element.setAttr('stroke', color);
      if (!cornerWidth) {
        element.setAttr('strokeWidth', 0);
      }
      setBgColor(color !== emptyColor ? color : undefined);
      propertyChanged();
    }
  };

  const changeBgEnable = () => {
    setBgEnabled(!isBgEnabled);
    updateState(!isBgEnabled);
    if (props.onEnabledChanged) {
      props.onEnabledChanged(!isBgEnabled);
    }
  };

  const updateState = (isEnabled) => {
    if (!isEnabled) {
      element.setAttr('_lastStateBackground', {
        cornerTL,
        cornerTR,
        cornerBR,
        cornerBL,
        bgColor,
        cornerWidth
      });
      changeBgColor(emptyColor);
      changeCorner(0);
      props.image && element.setAttr('strokeWidth', 0);
    } else {
      const state = element.getAttr('_lastStateBackground');
      if (state) {
        changeBgColor(state.bgColor);
        element.setAttr('cornerRadius', [
          parseInt(state.cornerTL),
          parseInt(state.cornerTR),
          parseInt(state.cornerBR),
          parseInt(state.cornerBL),
        ]);
        setCornerTL(state.cornerTL);
        setCornerTR(state.cornerTR);
        setCornerBR(state.cornerBR);
        setCornerBL(state.cornerBL);
        props.image && element.setAttr('strokeWidth', state.cornerWidth);
      }
    }
    propertyChanged();
  };

  const changeCornerTL = (value) => {
    if (parseInt(value) >= 0) {
      element.setAttr('cornerRadius', [
        parseInt(value),
        parseInt(cornerTR),
        parseInt(cornerBR),
        parseInt(cornerBL),
      ]);
      setCornerTL(value);
      propertyChanged();
    }
  };

  const changeCornerTR = (value) => {
    if (parseInt(value) >= 0) {
      element.setAttr('cornerRadius', [
        parseInt(cornerTL),
        parseInt(value),
        parseInt(cornerBR),
        parseInt(cornerBL),
      ]);
      setCornerTR(value);
      propertyChanged();
    }
  };

  const changeCornerBR = (value) => {
    if (parseInt(value) >= 0) {
      element.setAttr('cornerRadius', [
        parseInt(cornerTL),
        parseInt(cornerTR),
        parseInt(value),
        parseInt(cornerBL),
      ]);
      setCornerBR(value);
      propertyChanged();
    }
  };

  const changeCornerBL = (value) => {
    if (parseInt(value) >= 0) {
      element.setAttr('cornerRadius', [
        parseInt(cornerTL),
        parseInt(cornerTR),
        parseInt(cornerBR),
        parseInt(value),
      ]);
      setCornerBL(value);
      propertyChanged();
    }
  };

  const changeCorner = (value) => {
    if (parseInt(value) >= 0) {
      element.setAttr('cornerRadius', [
        parseInt(value),
        parseInt(value),
        parseInt(value),
        parseInt(value),
      ]);
      setCornerTL(value);
      setCornerTR(value);
      setCornerBR(value);
      setCornerBL(value);
      propertyChanged();
    }
  };

  const changeCornerWidth = (value) => {
    const parsedValue = parseInt(value);
    if (parsedValue < 0 || parsedValue > 100 || isNaN(parsedValue)) return;
    element.setAttr('strokeWidth', value);
    setCornerWidth(value);
    propertyChanged();
  };

  const changeBgBorderSeparatelyEnable = () => {
    setBgBorderSeparatelyEnabled(!isBgBorderSeparatelyEnabled);
    changeCorner(cornerTL ?? 0);
  };

  const propertyChanged = () => {
    if (props.onPropertyChanged) {
      props.onPropertyChanged();
    }
  };

  return (
    <div>
      <div className={globalStyles.spaceBetweenBlock}>
        <Text className={globalStyles.smallTitle}>
          {props.label || 'Background'}
        </Text>
        <Button
          shape='circle'
          type='text'
          icon={isBgEnabled ? <MinusIcon /> : <PlusIcon />}
          onClick={changeBgEnable}
        />
      </div>
      {isBgEnabled && (
        <div className={`${globalStyles.innerBlock} ${styles.wrapper}`}>
          <div className={globalStyles.spaceBetweenBlock}>
            <Text className={`${globalStyles.text}`}>Background colour:</Text>
            <ColorEditor
              value={bgColor}
              onChange={changeBgColor}
              type='simple'
            />
          </div>
          {bgColor &&
            <div className={globalStyles.spaceBetweenBlock}>
              <Button
                block
                className={`${globalStyles.text}`}
                onClick={() => changeBgColor(emptyColor)}
              >
                Reset colour
              </Button>
            </div>
          }
          {hasBorder && (
            <div
              className={`${globalStyles.selectBlock} ${globalStyles.gapBlock}`}
            >
              {props.image &&
                <div className={globalStyles.spaceBetweenBlock}>
                  <Text className={globalStyles.text}>Corner width:</Text>
                  <InputNumber
                    min={0}
                    max={100}
                    size="small"
                    onChange={changeCornerWidth}
                    prefix={<BorderSizeIcon />}
                    value={cornerWidth}
                    className={globalStyles.input}
                  />
                </div>
              }
              <div className={globalStyles.spaceBetweenBlock}>
                <Text className={globalStyles.text}>Corner radius:</Text>
                <InputNumber
                  min={0}
                  max={100}
                  size='small'
                  onChange={changeCorner}
                  prefix={<CornerRadiusIcon />}
                  disabled={isBgBorderSeparatelyEnabled}
                  value={isBgBorderSeparatelyEnabled ? '' : cornerTL}
                  className={globalStyles.input}
                />
              </div>
              <div className={globalStyles.spaceBetweenBlock}>
                <span className={globalStyles.smallTitle}>Set separately</span>
                <Button
                  type='text'
                  shape='circle'
                  icon={
                    isBgBorderSeparatelyEnabled ? <MinusIcon /> : <PlusIcon />
                  }
                  onClick={changeBgBorderSeparatelyEnable}
                />
              </div>

              {isBgBorderSeparatelyEnabled && (
                <div className={styles.cornersBlock}>
                  <div className={globalStyles.spaceBetweenBlock}>
                    <InputNumber
                      min={0}
                      max={100}
                      size='small'
                      prefix={<CornerTopLeft />}
                      controls={false}
                      value={cornerTL}
                      onChange={changeCornerTL}
                      className={globalStyles.input}
                    />
                    <InputNumber
                      min={0}
                      max={100}
                      size='small'
                      prefix={<CornerTopRight />}
                      controls={false}
                      value={cornerTR}
                      onChange={changeCornerTR}
                      className={globalStyles.input}
                    />
                  </div>

                  <div className={globalStyles.spaceBetweenBlock}>
                    <InputNumber
                      min={0}
                      max={100}
                      size='small'
                      prefix={<CornerBottomLeft />}
                      controls={false}
                      value={cornerBL}
                      onChange={changeCornerBL}
                      className={globalStyles.input}
                    />
                    <InputNumber
                      min={0}
                      max={100}
                      size='small'
                      prefix={<CornerBottomRight />}
                      controls={false}
                      value={cornerBR}
                      onChange={changeCornerBR}
                      className={globalStyles.input}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
