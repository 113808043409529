import React, {useEffect, useState} from 'react';
import { Button, Spin } from 'antd';

import {convertNoticeCode} from "../../../../helpers";
import { NoticeCode } from '../../../../constants';
import {updateBillingPlansFeatures} from "../BillingPlanHelpers/BillingPlanHelpers";
import BillingPlanCard from "../BillingPlanCard/BillingPlanCard";
import { ReactComponent as BackArrow } from 'svg/icon/arrow.svg';

import styles from './BillingPlansBlock.module.css'

const { ABSENT } = NoticeCode;

const BillingPlansBlock = ({
                               selectedOrganization,
                               defaultBillingPlans,
                               additionalPlans,
                               getBillingPlan,
                               getPaymentLink,
                               switchPlan,
                               billingPlanLoading,
                               closeBlock,
                               billingPlansDisabled = false,
                               isCreating = false,
                               isOrganizationCreateSuccess = false,
                           }) => {

    const [billingPlans, setBillingPlans] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getBillingPlansWithUpdate = async (loader, id) => {
            const givenBillingPlan = await getBillingPlan(loader, id);

            if (givenBillingPlan && givenBillingPlan.length) {
                setBillingPlans(updateBillingPlansFeatures(givenBillingPlan));
            }
        };

        if (selectedOrganization && selectedOrganization?.notice_code !== 0) {
            void getBillingPlansWithUpdate(setLoading, selectedOrganization.id);
        } else if (isCreating) {
            void getBillingPlansWithUpdate(setLoading);
        } else {
            const customPlans = additionalPlans.find(plan => plan.id === selectedOrganization.id)?.custom_plans;
            const allPlans = customPlans && customPlans.length ? [...defaultBillingPlans, ...customPlans] : [...defaultBillingPlans];
            setBillingPlans(updateBillingPlansFeatures(allPlans));
        }

    }, [selectedOrganization, additionalPlans]);

    const checkNoticeCode = (value) => {
        if (isCreating) return true;
        return convertNoticeCode(value);
    };

    const isActivePlan = (plan, isCreating = false) => {
      if (isCreating) return false;

      return selectedOrganization?.monthly_report?.plan?.id === plan.id && (selectedOrganization?.notice_code !== 4 && selectedOrganization?.notice_code !== 0)
    };

    const getActivePlan = () => {
      if (selectedOrganization?.notice_code !== 4 && selectedOrganization?.notice_code !== 0) {
        return selectedOrganization?.monthly_report?.plan;
      }
      return null;
    };

    return (
        <div>
          <div className={styles.header}>
            {!isCreating && selectedOrganization.notice_code !== ABSENT &&
              <Button
                type="text"
                shape="circle"
                className={styles.backButton}
                onClick={closeBlock}
                icon={<BackArrow/>}
              />
            }
            <h4 className={styles.blockHeader}>Choose plan</h4>
          </div>
          <div className={styles.billingsContainer}>
                {loading && billingPlans && billingPlans.length === 0 ?
                    <div className={styles.loading}>
                        <Spin/>
                    </div> :
                    <>
                        {!!billingPlans.length && billingPlans.map((plan) => (
                            <BillingPlanCard
                                plan={plan}
                                key={plan.id}
                                onSelect={!checkNoticeCode(selectedOrganization?.notice_code) ? switchPlan : getPaymentLink}
                                active={isActivePlan(plan, isCreating)}
                                activePlan={getActivePlan()}
                                disabled={billingPlansDisabled}
                                loading={billingPlanLoading === plan.id}
                                isCreating={isCreating}
                                isOrganizationCreateSuccess={isOrganizationCreateSuccess}
                            />
                        ))}
                        <BillingPlanCard
                            isOrganizationCreateSuccess={isOrganizationCreateSuccess}
                            plan={{
                                cost: 'flex',
                                feed_count: 30000,
                                features: [
                                    'For large projects',
                                    'Dedicated support',
                                    'Zero limits',
                                ]
                            }}
                        />
                    </>
                }
            </div>
        </div>
    );
};

export default BillingPlansBlock;
